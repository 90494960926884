<template>
  <div class="container">
    <div class="filter">
      <el-form :inline="true" :model="formInline" class="form-inline">
        <el-form-item label="院校名称">
          <el-input v-model="formInline.collegeName" placeholder="院校名称" @input="handleInputChange"></el-input>
        </el-form-item>
        <el-form-item label="省份">
          <el-select v-model="formInline.region" placeholder="请选择省份" @change="handleProvinceChange">
            <el-option :label="item" v-for="(item, index) in provinceList" :key="index" :value="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary">查询</el-button>
          <el-button type="primary" @click="save">保存</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table class="universityList" :data="universityList" border style="width: 972px;margin: auto;">
      <el-table-column prop="CollegeCode" label="编号" align="center" width="100"></el-table-column>
      <el-table-column prop="CollegeName" label="院校名称" width="180"></el-table-column>
      <el-table-column prop="iconUrl" label="院校图标" width="180" align="center">
        <template slot-scope="scope">
          <div style="display: flex; justify-content: center; align-items: center; height: 100%;">
            <img :src="scope.row.CoverImage" alt="院校图标" style="width: 50px; height: 50px;">
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="imageUrl" label="院校图片" width="180">
        <template slot-scope="scope">
          <el-carousel height="100px" indicator-position="none">
            <el-carousel-item v-for="item in scope.row.imageUrlList" :key="item">
              <img :src="item.imageUrl" alt="院校图片" style="width: 100%;">
            </el-carousel-item>
          </el-carousel>
        </template>
      </el-table-column>
      <el-table-column label="上传图片" width="180">
        <template slot-scope>
          <el-upload :action="upLoadnewURL" list-type="picture-card" :auto-upload="true" :multiple="true" :limit="50"
            :file-list="fileList" @change="handleFileChange">
            <i slot="trigger" class="el-icon-plus"></i>
            <div slot="file" slot-scope="{file, fileList}">
              <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
              <span class="el-upload-list__item-actions">
                <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                  <i class="el-icon-zoom-in"></i>
                </span>
                <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file, fileList)">
                  <i class="el-icon-delete"></i>
                </span>
              </span>
            </div>
          </el-upload>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import axios from 'axios';


export default {
  name: 'HomeView',
  data() {
    return {
      selectedId: '', // 用于存储选中的ID
      upLoadUrl: 'https://cdnimg.huaicai.cc/tuPian/upLoad/1',

      fileList: [],
      formInline: {
        collegeName: '',
        region: ''
      },
      provinceList: [],
      universityList: [],
      collegeImgs:{},
    }
  },
  created() {
    this.queryProvince()
  },
  computed: {
    upLoadnewURL() {
      console.log("选中的ID是:", this.selectedId);
      return this.upLoadUrl + this.selectedId;
    }
  },
  methods: {
    // 图片上传
    handleFileChange(res, file) {
      console.log(res, file);
    },
    save() {
      axios.post('https://gaokao.huaicai.cc/api/college/img/save', this.universityList).then(res => {
       console.log(res);
      })
    },
    //省份选择
    handleProvinceChange(selectedProvince) {
      this.loadUniversityList(selectedProvince);
    },
    //省份
    queryProvince() {
      axios.get('https://gaokao.huaicai.cc/api/college/get/province').then(res => {
        console.log("所有省份", res);
        this.provinceList = res.data.Data
      }).catch(error => {
        console.error("获取省份列表失败", error);
      });
    },
    // 学院
    loadUniversityList(province) {
      axios.get('https://gaokao.huaicai.cc/api/college', {
        params: {
          appkey: '1',
          keywords: 1,
          pagesize: 10000,
          pageindex: 1
        }
      }).then(res => {
        console.log("所有学院", res);
        this.universityList = res.data.Data.Records.filter(university => university.Province === province);
      }).catch(error => {
        console.error("获取学院列表失败", error);
      });
    }
  }
}
</script>

<style>
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-inline {
  margin: auto;
}
</style>
